import React, { useEffect, useState } from 'react'
//import { ScrollContainer } from '../fx/scroll-position/ScrollContainer'
import { ScrollContainer } from '../../context/scrollBehavior'

// example import of a component
import ArtworkGrid from '../layout/landingPage/ArtworkGrid'
import ArtistGrid from '../layout/landingPage/ArtistGrid'
import HowCanWeHelp from '../layout/landingPage/HowCanWeHelp'
import EmailForm from '../layout/landingPage/EmailForm'
import InfoBlock from '../layout/landingPage/InfoBlock'
import Exhibition from '../layout/landingPage/Exhibition'
import VirtualTour from '../layout/landingPage/VirtualTour'

const ParagraphBlockElement = ({blocks, device, title, page_data, drupal_id}) => {


    // example assignment of a component by type
    // make sure the key matches the "Type" field
    // for the block

    // console.log('blocks', blocks);

    return (
        <React.Fragment>
{/*           <ScrollContainer force={true}>
            <section className="grid-section grid-content-container"> */}

                  {blocks.map((block, i) => {
                    // this is the individual block
                    // we can determine the component by 
                    // looking at the "type" field
                    const { type, additional_info } = block;
                    // console.log("type", type, "addtional_info", additional_info);
                    // assign the component based on the type name
                    // using switch to match conditions
                    let Component = null;
                    switch(true) {
                      case type === 'nodes_list' && additional_info.content_type === 'artwork':
                        Component = ArtworkGrid
                      break;
                      case type === 'nodes_list' && additional_info.content_type === 'artist':
                        Component = ArtistGrid
                      break;
                      case type === "nodes_list" && additional_info.content_type === "exhibition":
                        Component = Exhibition;
                      break;
                      case type === "nodes_list" && additional_info.content_type === "virtual_tour":
                        Component = VirtualTour;
                      break;
                      case type === 'how_can_we_help':
                        Component = HowCanWeHelp
                      break;
                      case type === 'form':
                        Component = EmailForm
                      break;
                      case type === 'info_block':
                        Component = InfoBlock
                      break;
                    }
                    // this is referenced below as <Component />
                    // we're spreading the block data as props
                    // to the Component component
                    // these will be accessible via props in each individual component
                    if (Component === null) return null;

                    return (
                      <ScrollContainer key={i} force={i < 1 ? true : false}>
                        <Component index={i} drupal_id={drupal_id} page_data={page_data} {...block} title={title} device={device}/>
                      </ScrollContainer>
                      )
                    })}
{/*             </section>
          </ScrollContainer> */}
        </React.Fragment>
    )
}

export default ParagraphBlockElement
