/* eslint-disable react/jsx-indent */
import React from 'react'

import AnimateOnEntrance from '../../fx/animate/AnimateOnEntrance'
import Link from '../../global/Link'
import ImageInView from '../../fx/animate/ImageInView';

export default function InfoBlock({block, device, title}) {

    let path = title === 'Historical' ? `/artworks/type/Historical%20works%20of%20significance` : '/artworks'
    path = title === 'Contemporary'  ? `/artworks/type/Established%20living%20contemporary` : path
    path = title === 'Homepage' ? `/artworks` : path
    
    let btnText = title === 'Historical' ? `Explore Our Collection` : 'Use our search tool to find artwork'
    btnText = title === 'Contemporary'  ? `Explore Our Collection` : 'Use our search tool to find artwork'
    btnText = title === 'Homepage' ? 'Use our search tool to find artwork' : `Explore Our Collection`

    return (
        <section className="grid-section callout-section grid-content-container t-center">
            <div className="introAnimateBottomLeft">   
                <ImageInView src={"/media/grid_bl.jpg"} />
            </div>
            <div className="introAnimateTopRight">
                <ImageInView src={"/media/grid_tr.jpg"} />
            </div>
            {block.body?.value ? (
                <React.Fragment>
                    <AnimateOnEntrance className="fadeInUp" device={device}>
                    <div className="t-center photo-frame" dangerouslySetInnerHTML={{ __html: block.body.value }}></div>
                    </AnimateOnEntrance>
                    <AnimateOnEntrance className="fadeInUp" device={device}>
                        <Link
                            button
                            className={`btn-large btn-center btn-space-xlarge`}
                            path={path}
                            linkkey={`search_btn`}
                            device={device}
                            >
                            {btnText}
                        </Link> 
                    </AnimateOnEntrance>
                </React.Fragment>
            ): (
                <React.Fragment>
                    <AnimateOnEntrance className="fadeInUp" device={device}>
                        <h1 className="t-xlarge t-heading t-center">Search {title} Artwork</h1>
                    </AnimateOnEntrance>
                    <AnimateOnEntrance className="fadeInUp" device={device}>
                        <Link
                            button
                            className={`btn-large btn-center btn-space-xlarge`}
                            path={path}
                            linkkey={`search_btn`}
                            device={device}
                            >
                            {btnText}
                        </Link> 
                    </AnimateOnEntrance>
                </React.Fragment>
            ) }
            
        </section>
    )
}