import React from 'react';
import { Link } from 'gatsby';
import VirtualTourComp from '../../global/VirtualTour';
import RenderContentInView from '../../fx/animate/RenderContentInView';
import CompLink from '../../global/Link';

const VirtualTour = ({ title, view_more_link, items }) => {
  return (
    <section className="grid-section grid-content-container">
      <div className="t-heading-container">
        <h2 className="t-xlarge t-heading t-uppercase sub-section-title">
          Virtual Tours
        </h2>
      </div>

      {items.map(({ itemNode: item }, i) => (
        <RenderContentInView>
          <VirtualTourComp virtualTour={item} key={i} />
        </RenderContentInView>
      ))}

      <section className="t-content-container">
        <div className="t-right t-center-sm">
          <CompLink
            simplebutton
            className={`btn-large btn-center`}
            path={`/virtual-tours/`}
            linkkey={`exhibit_btn`}
          >
            View all Virtual Tours
          </CompLink>
        </div>
      </section>

      {/*
      view_more_link && view_more_link.uri ? (
        <div className="t-right t-center-sm">
          <Link
            path={view_more_link.uri}
            simplebutton
            className={`btn-large btn-center`}
          >
            {view_more_link.title}
          </Link>
        </div>
      ) : null
      */}
    </section>
  );
};

export default VirtualTour;
