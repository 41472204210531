import React, { useState, useEffect } from 'react';
import Link from '../../global/Link';
import ArtistCard from '../../global/ArtistCard';
import useGetArtists from '../../../util/hooks/useGetArtists';

export default function ArtistGrid({
  title,
  view_more_link,
  device,
  index,
  drupal_id,
  page_data,
}) {
  const [itemNodes, setItemNodes] = useState([]);
  const { artists, setArtistIds, loadingArtists } = useGetArtists();

  // get the random ids from the drupal endpoint that was passed down from
  useEffect(() => {
    if (page_data?.length > 0) {
      const item = page_data.find(
        (item) => item.paragraph_id === `${drupal_id}_${index}`
      );

      let ids = [];

      if (item) {
        for (const prop in item.nodes) {
          ids.push(item.nodes[prop].uuid);
        }
      }

      setItemNodes(ids);
    }
  }, [page_data, setItemNodes]);

  // set the artworks once we have the array of random artwork ids
  useEffect(() => {
    setArtistIds(itemNodes);
  }, [itemNodes, setArtistIds]);

  return (
    <section className="grid-section grid-content-container t-left artist-grid">
      {title ? (
        <div className="t-heading-container">
          <h2 className="t-xlarge t-heading t-uppercase sub-section-title">
            {title} Artists
          </h2>
        </div>
      ) : null}
      <div className="t-content-container">
        <div className="grid-4 grid-2-md grid-1-sm grid-justify-center">
          {loadingArtists ? (
            <>
              {new Array(8).fill(0).map((_, i) => (
                <div key={i} className="artwork-skeleton grid-col"></div>
              ))}
            </>
          ) : artists && artists.length > 0 ? (
            <>
              {artists.map(({ data: artist }, i) => (
                <ArtistCard
                  artist={artist}
                  device={device}
                  key={artist.id}
                  showFollowing={true}
                  className="grid-col"
                  json={true}
                />
              ))}
            </>
          ) : null}
        </div>
      </div>
      {view_more_link && view_more_link.uri ? (
        <>
          <div className="spacer"></div>
          <div className="t-right t-center-sm">
            <Link
              path={view_more_link.uri}
              simplebutton
              className={`btn-large btn-center btn-letterspacing`}
            >
              {view_more_link.title}
            </Link>
          </div>
        </>
      ) : null}
    </section>
  );
}
