import React from 'react';
import Link from '../../global/Link';
import { SimpleCarousel, CarouselItem } from '../../global/SimpleCarousel';
import ImageFromField from '../../global/ImageFromField';
import dateFormat from '../../helper/dateFormat';
import CompLink from '../../global/Link';
import returnWithFallback from '../../../util/helper/returnWithFallback';
import ExhibitionCard from '../../global/ExhibitionCard';

// For historical, contemporary, homepage landing page
const Exhibition = ({ items, block_title, title, device }) => {
  return (
    <section className="grid-section grid-content-container t-left exh-slide exhibition-section">
      {items && items.length > 0 ? (
        <div className="t-heading-container">
          <h2 className="t-xlarge t-heading t-uppercase sub-section-title">
            {block_title}
          </h2>
        </div>
      ) : (
        <div className="t-heading-container">
          <h2 className="t-xlarge t-heading t-uppercase sub-section-title">
            {' '}
            {block_title}
          </h2>
          <h4 className="t-medium t-subheading t-uppercase">
            There are no {block_title}
          </h4>
          <div className="spacer"></div>
        </div>
      )}

      {items && items.length > 0 ? (
        <React.Fragment>
          <section className="t-content-container">
            <SimpleCarousel>
              {items.map(({ itemNode: exhibition }) => (
                <CarouselItem key={exhibition.title}>
                  <ExhibitionCard
                    exhibition={exhibition}
                    device={device}
                    viewButton={true}
                  />
                </CarouselItem>
              ))}
            </SimpleCarousel>
          </section>

          <section className="t-content-container">
            <div className="t-right t-center-sm">
              <CompLink
                simplebutton
                className={`btn-large btn-center btn-letterspacing`}
                path={`/exhibitions`}
                linkkey={`exhibit_btn`}
                device={device}
              >
                View all exhibitions
              </CompLink>
            </div>
          </section>
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}
    </section>
  );
};

export default Exhibition;
