import React, { useEffect, useState } from 'react';
import Link from '../../global/Link';
import ArtworkCard from '../../global/ArtworkCard';
import useGetArtworks from '../../../util/hooks/useGetArtworks';

export default function ArtworkGrid({
  block_title,
  view_more_link,
  device,
  index,
  drupal_id,
  page_data,
}) {
  const [itemNodes, setItemNodes] = useState([]);
  const { artworks, loadingArtworks, setArtworkIds } = useGetArtworks();

  let viewMoreLink =
    view_more_link &&
      view_more_link.uri &&
      view_more_link.uri.includes('internal:')
      ? view_more_link.uri.replace('internal:', '')
      : view_more_link.uri;

  // get the random ids from the drupal endpoint that was passed down from
  useEffect(() => {
    if (page_data?.length > 0) {
      const item = page_data.find(
        (item) => item.paragraph_id === `${drupal_id}_${index}`
      );

      let ids = [];

      if (item) {
        for (const prop in item.nodes) {
          ids.push(item.nodes[prop].uuid);
        }
      }

      setItemNodes(ids);
    }
  }, [page_data, setItemNodes]);

  // set the artworks once we have the array of random artwork ids
  useEffect(() => {
    setArtworkIds(itemNodes);
  }, [itemNodes, setArtworkIds]);

  return (
    <>
      {itemNodes.length > 0 ? (
        <section className="grid-section grid-content-container t-left artwork-grid">
          {block_title ? (
            <div className="t-heading-container">
              <h2 className="t-xlarge t-heading t-uppercase sub-section-title">
                {block_title}
              </h2>
            </div>
          ) : null}
          <div className="t-content-container">
            <div className="grid-4 grid-2-md grid-1-sm grid-justify-center">
              {loadingArtworks ? (
                <>
                  {new Array(4).fill(0).map((_, i) => (
                    <div key={i} className="artwork-skeleton grid-col"></div>
                  ))}
                </>
              ) : artworks && artworks.length > 0 ? (
                <>
                  {artworks.map(({ data: artwork }) => (
                    <ArtworkCard
                      artwork={artwork}
                      device={device}
                      key={artwork.id}
                      className={'grid-col'}
                      artist_drupal_id={
                        artwork.relationships.field_artist_ref.id
                      }
                    />
                  ))}
                </>
              ) : null}
            </div>
          </div>
          {viewMoreLink && view_more_link && view_more_link.uri ? (
            <>
              <div className="spacer"></div>
              <div className="t-right t-center-sm">
                <Link
                  path={viewMoreLink}
                  simplebutton
                  className={`btn-large btn-center btn-letterspacing`}
                >
                  {view_more_link.title}
                </Link>
              </div>
            </>
          ) : null}
        </section>
      ) : null}
    </>
  );
}
