import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export default ({threshold = 0, children}) => {
  const [visible, setVisible] = useState(false);
  const { ref, inView, entry } = useInView({
    threshold: threshold,
    rootMargin: '500px 0px 0px 0px',
  });

  useEffect(() => {
    if (inView && !visible) {
        setVisible(true);
    }
  }, [inView])

  return (
    <div 
      ref={ref}
    >
        {visible ? children : null}
    </div>
  );
}